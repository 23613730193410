'use client'
import * as React from 'react'
import CloseIconCircle from 'ui/icons/CloseIconCircle'
import Button from 'ui/components/Button'
import CartLineItem from 'ui/components/CartLineItem'
import Price from 'ui/components/Price'
import Link from 'next/link'
import { useCart } from 'medusa-react'
import { CartSidePanel } from 'web/src/lib/context/cart-show-context'
import Image from 'next/image'
import routes from 'web/src/lib/routes'
import classNames from 'classnames'
import messages from '../../data/messages'
import { useStore } from 'web/src/lib/context/store-context'

const CartOverlay = () => {
    const { cart } = useCart()
    const { quantityError } = useStore()
    const { state, close } = CartSidePanel()

    return (
        <div>
            <div
                onClick={close}
                className={classNames({
                    'duration-400 fixed inset-0 z-10 w-full bg-black/50 ease-in': state,
                })}
            />
            <div
                className={classNames(
                    'fixed left-0 top-0 z-50 flex h-full w-full translate-y-full flex-col gap-6 overflow-y-auto bg-white p-6 opacity-0 ease-in [transition:transform_0.4s,opacity_0.4s] lg:left-auto lg:right-0 lg:h-screen lg:max-w-[600px] lg:translate-x-full lg:translate-y-0 lg:rounded-tr-none',
                    {
                        '!translate-y-0 opacity-100 lg:!translate-x-0': state,
                    },
                )}
            >
                {cart && (
                    <>
                        <div className="flex items-center justify-between">
                            <h3 className="text-2xl font-bold">Winkelwagen</h3>
                            <div className="cursor-pointer" onClick={close}>
                                <CloseIconCircle />
                                <span className="sr-only">Close</span>
                            </div>
                        </div>
                        <div className="flex flex-col gap-6 border-t border-black pb-0 pt-4">
                            {cart?.items?.length > 0 ? (
                                cart.items.map(item => (
                                    <CartLineItem
                                        onClick={close}
                                        id={item.id}
                                        quantity={item.quantity}
                                        prices={item.variant.prices}
                                        price={item.total}
                                        title={item.title}
                                        image={
                                            <Image src={item.thumbnail || ''} alt={item.title} width={96} height={96} />
                                        }
                                        key={item.id}
                                        handle={`${routes.product}/${item.variant?.product?.handle}`}
                                    />
                                ))
                            ) : (
                                <p className="font-medium">U heeft nog geen producten in uw winkelwagen</p>
                            )}
                            {quantityError && <p className="text-brand-red text-xs">{quantityError}</p>}
                        </div>
                        {cart?.items?.length > 0 && (
                            <div>
                                <div className="flex items-center justify-between border-t border-black pt-4">
                                    <p className="font-medium">Verzendkosten</p>
                                    <p className="font-medium">Gratis</p>
                                </div>
                                <p className="mt-2">{messages.deliveryCost}</p>
                            </div>
                        )}
                        <div className="bg-brand-grey100 flex items-center justify-between border-t border-t-black p-4">
                            <p className="font-medium">Totaal</p>
                            <Price price={cart && (cart.subtotal || 0) + (cart?.tax_total || 0)} />
                        </div>
                        <div className="flex flex-col gap-2">
                            {/*// @ts-ignore*/}
                            <Button as={Link} href="/winkelmand" variant="dark" onClick={close}>
                                Bekijk Winkelwagen
                            </Button>
                            <Button
                                // @ts-ignore
                                as={Link}
                                variant="primary"
                                disabled={cart?.items?.length < 1}
                                href="/bestellen"
                                onClick={close}
                            >
                                Afrekenen
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default CartOverlay
