'use client'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import CheckIcon from '../../icons/CheckIcon'
import CloseIconCircle from '../../icons/CloseIconCircle'
import { useNewCarOwnerHook } from 'web/src/lib/hooks/useNewCarOwnerHook'
import { useCart } from 'medusa-react'
import { useStore } from 'web/src/lib/context/store-context'

const SnackBar = ({ isSuccess = false, autoHide = 3000 }: { isSuccess?: boolean; autoHide?: number }) => {
    const { cart } = useCart()
    //@ts-ignore
    const newCarOwnerError = useNewCarOwnerHook(cart)
    const { snackbarError } = useStore()
    const [isShown, setIsShown] = useState(false)

    useEffect(() => {
        let submittedTimeout: NodeJS.Timeout

        if (newCarOwnerError || snackbarError) {
            // Add a micro delay on the shown property, to make sure the fadeIn animation works
            submittedTimeout = setTimeout(() => setIsShown(true), 1)
        }

        if (autoHide) {
            submittedTimeout = setTimeout(() => setIsShown(false), autoHide)
        }

        return () => {
            clearTimeout(submittedTimeout)
        }
    }, [newCarOwnerError, snackbarError])

    return (
        <div
            className={classNames(
                'ease fixed bottom-0 left-0 z-40 flex h-16 w-full translate-y-full transform-gpu items-center justify-center opacity-0 transition-all duration-500',
                {
                    '!translate-y-[0] !opacity-100 ': isShown,
                },
                {
                    'bg-red-600 text-white': !isSuccess,
                    'bg-green-400 text-white': isSuccess,
                }
            )}
        >
            {isSuccess ? (
                <CheckIcon fill={'#fff'} className="h-6 w-6" />
            ) : (
                <CloseIconCircle fill={'#fff'} className="h-6 w-6" />
            )}
            <p className={'ml-3.5 font-medium'}>{newCarOwnerError || snackbarError}</p>
        </div>
    )
}

export default SnackBar
