import React, { FC, SVGAttributes } from 'react'

const CheckIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none" viewBox="0 0 17 16" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M7.674 14.793L15.97 2.909A1.533 1.533 0 0015.84.954l-.008-.008-.008-.008a1.5 1.5 0 00-2.287.208L6.296 11.52 3.24 7.142a1.5 1.5 0 00-2.286-.209l-.009.008-.008.009a1.533 1.533 0 00-.131 1.954l4.112 5.89a1.68 1.68 0 002.756 0z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export default CheckIcon
