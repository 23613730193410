'use client'

import * as React from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'
import classNames from 'classnames'

const Label = React.forwardRef<
    React.ElementRef<typeof LabelPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => (
    <LabelPrimitive.Root
        ref={ref}
        className={classNames(
            'text-grey800 mb-2 text-base font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70 lg:mb-0',
            className
        )}
        {...props}
    />
))
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
