'use client'
import { usePathname } from 'next/navigation'
import React from 'react'
import Link from 'next/link'
import ChevronRightIcon from '../../icons/ChevronRightIcon'
import classNames from 'classnames'
import { checkBreadcrumbsUrl } from 'web/src/utils/checkBreadcrumbsUrl'
import { useCart } from 'medusa-react'

export default function NextBreadcrumbs() {
    const path = usePathname()
    const { cart } = useCart()
    const modelFromContext = cart ? (cart?.context?.model as string) : undefined
    const categoryFromContext = cart ? (cart?.context?.category as string) : undefined
    const productFromContext = cart ? (cart?.context?.product as string) : undefined

    function generateBreadcrumbs() {
        if (!path || !cart?.id) return null
        const asPathWithoutQuery = path.split('?')[0]
        const asPathNestedRoutes = asPathWithoutQuery.split('/').filter(v => v.length > 0 && !v.includes('cart_'))
        if (asPathNestedRoutes) {
            const crumbList = asPathNestedRoutes.reduce(
                (acc: { href: string; text: string }[], subpath: string, index) => {
                    if (subpath === 'product') {
                        // @ts-ignore
                        const categoryHandle = categoryFromContext?.handle
                        if (categoryHandle) {
                            return [
                                ...acc,
                                {
                                    href: `/`,
                                    text: 'Webshop',
                                },
                                {
                                    href: `/category/${categoryHandle}`,
                                    text: categoryHandle,
                                },
                            ]
                        } else if (modelFromContext && typeof categoryFromContext === 'string') {
                            return [
                                ...acc,
                                {
                                    href: `/`,
                                    text: 'Webshop',
                                },
                                {
                                    href: `/model/${modelFromContext}`,
                                    text: modelFromContext,
                                },
                                {
                                    href: `/model/${modelFromContext}/${categoryFromContext}`,
                                    text: categoryFromContext,
                                },
                            ]
                        } else if (modelFromContext) {
                            return [
                                ...acc,
                                {
                                    href: `/`,
                                    text: 'Webshop',
                                },
                                {
                                    href: `/model/${modelFromContext}`,
                                    text: modelFromContext,
                                },
                            ]
                        } else {
                            return [...acc]
                        }
                    } else if (subpath === 'model') {
                        return [
                            ...acc,
                            {
                                href: `/`,
                                text: 'Webshop',
                            },
                        ]
                    } else if (subpath === 'winkelmand') {
                        return [
                            ...acc,
                            {
                                href: `/`,
                                text: 'Webshop',
                            },
                            {
                                href: '/',
                                text: 'Winkelmand',
                            },
                        ]
                    }
                    const href = '/' + asPathNestedRoutes?.slice(0, index + 1).join('/')
                    const text =
                        subpath === 'product' ? (productFromContext ? productFromContext : '') : subpath ? subpath : ''

                    if (asPathNestedRoutes?.[0] === 'category') {
                        return acc
                    }

                    return [...acc, { href, text }]
                },
                [],
            )

            return [...crumbList]
        }
    }

    const breadcrumbs = generateBreadcrumbs()
    if (!breadcrumbs) return <></>
    return (
        <>
            {!checkBreadcrumbsUrl(path) && (
                <nav
                    className={classNames(`container flex flex-wrap items-center pt-8 md:pt-0`, {
                        'hidden lg:block': path === '/',
                    })}
                    aria-label="breadcrumb"
                >
                    <ol className="flex flex-wrap items-center">
                        {breadcrumbs?.map((crumb, index) => (
                            <Crumb {...crumb} key={index} last={index === breadcrumbs.length - 1} />
                        ))}
                    </ol>
                </nav>
            )}
        </>
    )
}

function Crumb({ text, href, last = false }: { text: string; href: string; last: boolean }) {
    const replaceRegex = /(-)|(%20)/g

    if (!text) {
        return null
    }

    if (last) {
        return (
            <li aria-current="page">
                <span className="line-clamp-1 capitalize text-black/60 md:text-white/60 w-[200px] max-w-[calc(100vw-48px)]">
                    {text.toString().replace(replaceRegex, ' ')}
                </span>
            </li>
        )
    }

    return (
        <li className="flex w-fit items-center">
            <Link
                color="inherit"
                className="hover:text-brand-red font-medium capitalize text-black md:text-white"
                href={href}
            >
                {text.toString().replace('-', ' ')}
            </Link>
            <ChevronRightIcon className="shrink-0 [&>path]:fill-black md:[&>path]:fill-white" />
        </li>
    )
}
