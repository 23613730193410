import { gtagEnabled } from './index'
import { formatMedusaPrice } from '../formatMedusaPrice'
import { ProductVariant } from '@medusajs/medusa'
export const trackRemoveFromCart = (variant: ProductVariant, quantity: number, price?: number) => {
    if (gtagEnabled()) {
        gtag('event', 'remove_from_cart', {
            currency: 'EUR',
            value: formatMedusaPrice(variant?.prices?.[0].amount),
            items: [
                {
                    item_name: variant.title,
                    item_id: variant.id,
                    quantity: quantity,
                    price: price,
                },
            ],
        })
    }
}
