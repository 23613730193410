import React, { FC, SVGAttributes } from 'react'

const HamburgerIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.62875 6.00781H18.3788C18.5777 6.00781 18.7684 6.08683 18.9091 6.22748C19.0497 6.36813 19.1288 6.5589 19.1288 6.75781C19.1288 6.95672 19.0497 7.14749 18.9091 7.28814C18.7684 7.42879 18.5777 7.50781 18.3788 7.50781H5.62875C5.42984 7.50781 5.23908 7.42879 5.09842 7.28814C4.95777 7.14749 4.87875 6.95672 4.87875 6.75781C4.87875 6.5589 4.95777 6.36813 5.09842 6.22748C5.23908 6.08683 5.42984 6.00781 5.62875 6.00781V6.00781ZM5.62875 11.2578H18.3788C18.5777 11.2578 18.7684 11.3368 18.9091 11.4775C19.0497 11.6181 19.1288 11.8089 19.1288 12.0078C19.1288 12.2067 19.0497 12.3975 18.9091 12.5381C18.7684 12.6788 18.5777 12.7578 18.3788 12.7578H5.62875C5.42984 12.7578 5.23908 12.6788 5.09842 12.5381C4.95777 12.3975 4.87875 12.2067 4.87875 12.0078C4.87875 11.8089 4.95777 11.6181 5.09842 11.4775C5.23908 11.3368 5.42984 11.2578 5.62875 11.2578V11.2578ZM5.62875 16.5078H18.3788C18.5777 16.5078 18.7684 16.5868 18.9091 16.7275C19.0497 16.8681 19.1288 17.0589 19.1288 17.2578C19.1288 17.4567 19.0497 17.6475 18.9091 17.7881C18.7684 17.9288 18.5777 18.0078 18.3788 18.0078H5.62875C5.42984 18.0078 5.23908 17.9288 5.09842 17.7881C4.95777 17.6475 4.87875 17.4567 4.87875 17.2578C4.87875 17.0589 4.95777 16.8681 5.09842 16.7275C5.23908 16.5868 5.42984 16.5078 5.62875 16.5078V16.5078Z"
            fill="white"
        />
    </svg>
)

export default HamburgerIcon
