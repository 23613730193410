'use client'
import { useSearchParams } from 'next/navigation'
import { medusaClient } from 'web/src/lib/config'
import { useEffect, useState } from 'react'
import { Cart } from '@medusajs/medusa'

/**
 * Get the new car owner email from params
 * Fetch the prefilled cart from backend
 * Replace current cart
 */
export const useNewCarOwnerHook = (cart?: Omit<Cart, 'refundable_amount' | 'refunded_total'>) => {
    const searchParams = useSearchParams()
    const newCarOwnerEmail = searchParams.get('newCarOwner')
    const cartId = cart?.id
    const [queried, setQueried] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const newCarOwnerQuery = async () => {
        setError(null)

        await medusaClient.client
            .request('GET', `/store/new-car-owner/${cartId}/?email=${newCarOwnerEmail}`)
            .then(() => {
                return
            })
            .catch(() => {
                setError('Er ging wat mis met het ophalen van de data')
            })
    }

    useEffect(() => {
        if (!queried && newCarOwnerEmail && cartId) {
            setQueried(true)
            newCarOwnerQuery()
        }
    }, [queried, newCarOwnerEmail, cartId])

    return error
}
