import React, { FC, SVGAttributes } from 'react'

const TrashIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.3789 7.50785H5.62891C5.42999 7.50785 5.23923 7.42883 5.09858 7.28818C4.95792 7.14753 4.87891 6.95676 4.87891 6.75785V6.00785C4.87891 5.80894 4.95792 5.61817 5.09858 5.47752C5.23923 5.33687 5.42999 5.25785 5.62891 5.25785H7.87891C7.87891 4.15858 8.10968 3.51688 8.62313 3.00331C9.13658 2.48975 9.77663 2.25879 10.8755 2.25879H13.1327C14.2314 2.25879 14.8716 2.48968 15.3849 3.00331C15.8983 3.51695 16.1289 4.15858 16.1289 5.25785H18.3789C18.5778 5.25785 18.7686 5.33687 18.9092 5.47752C19.0499 5.61817 19.1289 5.80894 19.1289 6.00785V6.75785C19.1289 6.95676 19.0499 7.14753 18.9092 7.28818C18.7686 7.42883 18.5778 7.50785 18.3789 7.50785ZM14.2568 4.1306C14.1063 3.99213 13.928 3.8873 13.7338 3.82311C13.5396 3.75891 13.334 3.73682 13.1306 3.7583H10.8773C10.674 3.73683 10.4683 3.75893 10.2742 3.82313C10.08 3.88732 9.90171 3.99214 9.75121 4.1306C9.61278 4.28135 9.50801 4.45983 9.44385 4.65418C9.37969 4.84854 9.35762 5.05431 9.37909 5.25785H14.6291C14.6506 5.05431 14.6285 4.84854 14.5643 4.65418C14.5002 4.45983 14.3954 4.28135 14.257 4.1306H14.2568ZM17.6252 8.25785C17.6765 8.25774 17.7273 8.26815 17.7745 8.28842C17.8216 8.3087 17.8641 8.33843 17.8993 8.37576C17.9345 8.41309 17.9616 8.45724 17.9791 8.50548C17.9966 8.55373 18.004 8.60504 18.0009 8.65625L17.3395 19.203C17.321 19.4892 17.1942 19.7576 16.9848 19.9536C16.7754 20.1495 16.4992 20.2583 16.2124 20.2579H7.79472C7.50794 20.2583 7.23175 20.1495 7.02237 19.9536C6.81298 19.7576 6.68614 19.4892 6.66766 19.203L6.00604 8.65625C6.00294 8.60504 6.01036 8.55373 6.02785 8.50549C6.04533 8.45725 6.07252 8.41311 6.10772 8.37578C6.14292 8.33845 6.1854 8.30873 6.23253 8.28845C6.27966 8.26816 6.33045 8.25775 6.38176 8.25785H17.6252Z"
            fill="#000000"
        />
    </svg>
)

export default TrashIcon
