import MinusIcon from '../../icons/MinusIcon'
import PlusIcon from '../../icons/PlusIcon'
import Price from '../../components/Price/index'
import Button from '../Button/index'
import { CartLineItemProps } from './type'
import ThrashIcon from '../../icons/TrashIcon'
import { useStore } from 'web/src/lib/context/store-context'
import Link from 'next/link'
import { useCart } from 'medusa-react'
import classNames from 'classnames'
import * as React from 'react'
import LoadingIcon from '../../icons/LoadingIcon'
import { trackAddToCart } from 'web/src/utils/tracking/trackAddToCart'
import { trackRemoveFromCart } from 'web/src/utils/tracking/trackRemoveFromCart'
import { trackAddToCartLineItem } from 'web/src/utils/tracking/trackAddToCartLineItem'
import { trackRemoveFromCartLineItem } from 'web/src/utils/tracking/trackRemoveFromCartLineItem'
import getVariantPrice from 'web/src/lib/util/getVariantPrice'

const CartLineItem = ({ quantity, price, prices, title, image, id, handle, onClick }: CartLineItemProps) => {
    const { updateItem, deleteItem, lineItemError, quantityUpdating } = useStore()
    const { cart } = useCart()
    const cartLineItem = cart?.items?.find(item => item.id === id)!
    const isLoading = !!quantityUpdating?.[`${cartLineItem?.id}`]

    const handleRemoveFromCart = (id: string) => {
        trackRemoveFromCart(cartLineItem.variant, 1, price || 0)
        deleteItem(id)
    }

    const handleAddItem = () => {
        trackAddToCartLineItem(cartLineItem, quantity + 1, price || 0)
        updateItem({ lineId: id, quantity: quantity + 1 })
    }

    const handleRemoveItem = () => {
        trackRemoveFromCartLineItem(cartLineItem, quantity - 1, price || 0)
        updateItem({ lineId: id, quantity: quantity - 1 })
    }

    const variantPrice = getVariantPrice(prices)
    const originalTotal = variantPrice?.originalPrice ? variantPrice.originalPrice * quantity : null

    return (
        <>
            <div className="flex gap-4">
                <Link href={handle} onClick={() => (onClick ? onClick() : null)}>
                    <div className="[&>img]:xs:h-24 [&>img]:xs:w-32 [&>img]:h-14 [&>img]:w-20 [&>img]:object-cover">
                        {image}
                    </div>
                </Link>
                <div className="flex w-full flex-col">
                    <div className="xs:pb-4 flex items-start justify-between gap-6 pb-2">
                        <Link href={handle} onClick={() => (onClick ? onClick() : null)}>
                            <p className="xs:text-base text-xs font-medium">{title}</p>
                        </Link>
                    </div>
                    <div className="flex items-center justify-between pl-1">
                        <div className="flex items-center">
                            {quantity === 1 ? (
                                <Button
                                    type="button"
                                    as="button"
                                    onClick={() => (!isLoading ? handleRemoveFromCart(id) : null)}
                                    className={classNames('transition-transform hover:scale-110', {
                                        'cursor-not-allowed opacity-30': isLoading,
                                    })}
                                >
                                    <ThrashIcon />
                                </Button>
                            ) : (
                                <Button
                                    type="button"
                                    as="button"
                                    className={classNames('transition-transform', {
                                        'cursor-not-allowed opacity-30': isLoading,
                                        'hover:scale-110': !isLoading,
                                    })}
                                    onClick={() => (!isLoading ? handleRemoveItem() : null)}
                                >
                                    <MinusIcon className="h-6 w-6" />
                                </Button>
                            )}
                            <div
                                className={classNames(
                                    'xs:mx-3 xs:h-10 xs:text-base mx-2 flex h-10 w-12 items-center justify-center border border-black p-2 text-center text-xs font-medium',
                                    {
                                        'opacity-30': isLoading,
                                    },
                                )}
                            >
                                {isLoading ? <LoadingIcon className="h-[25px] w-[25px]" /> : quantity}
                            </div>
                            <div
                                className={classNames('transition-transform', {
                                    'cursor-not-allowed opacity-30': isLoading,
                                    'hover:scale-110': !isLoading,
                                })}
                                onClick={() => {
                                    !isLoading ? handleAddItem() : null
                                }}
                            >
                                <PlusIcon className="h-6 w-6" />
                            </div>
                        </div>
                        <span className="flex flex-col">
                            {originalTotal && originalTotal !== price && (
                                <>
                                    <Price
                                        className="xs:text-base text-xs font-medium line-through opacity-50 !leading-tight"
                                        price={originalTotal}
                                    />
                                </>
                            )}
                            <Price className="xs:text-base text-xs font-medium !leading-tight" price={price} />
                        </span>
                    </div>
                </div>
            </div>
            {lineItemError && lineItemError?.lineId === id && (
                <p className="text-brand-red text-xs">{lineItemError?.error}</p>
            )}
        </>
    )
}

export default CartLineItem
