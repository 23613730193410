'use client'
import * as React from 'react'
import { useEffect, useRef } from 'react'
import Button from '../Button'
import { Dialog } from '@radix-ui/react-dialog'
import { DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '../Dialog'
import { Checkbox } from '../FormFields/Checkbox'
import { Label } from '../FormFields/Label'
import classNames from 'classnames'
import { useCookie } from 'web/src/lib/context/cookie-context'

const CookieNotification = () => {
    const ref = useRef<HTMLDivElement>(null)
    const {
        dialogOpen,
        setDialogOpen,
        showNotice,
        setShowNotice,
        cookiePreferences,
        updateCookiePreferences,
        acceptCookies,
    } = useCookie()

    const statisticChecked = cookiePreferences?.statistics
    const marketingChecked = cookiePreferences?.marketing
    useEffect(() => {
        if (ref.current) {
            if (showNotice) {
                ref.current.classList.remove('hidden')
            } else {
                ref.current.classList.add('hidden')
            }
        }
    }, [ref, showNotice])

    return (
        <>
            <div
                ref={ref}
                className="border-color-black fixed bottom-5 left-5 z-50 flex hidden w-96 flex-col gap-y-2 border bg-white bg-white p-4 max-w-[calc(100%-40px)]"
            >
                <p className="font-bold">Cookies van Mitsubishi?</p>
                <p className="">Accepteer onze cookies voor een optimale klantervaring.</p>
                <div className="flex flex-col gap-y-2">
                    <Button
                        onClick={() => {
                            setDialogOpen(true)
                            setShowNotice(false)
                        }}
                        as="button"
                        variant="outline"
                        type="button"
                        aria-label="Instellingen"
                    >
                        Instellingen
                    </Button>
                    <Button onClick={acceptCookies} as="button" aria-label="Accepteer cookies" variant="primary">
                        Accepteer cookies
                    </Button>
                </div>
            </div>
            <Dialog
                open={dialogOpen}
                onOpenChange={() => {
                    setDialogOpen(!dialogOpen)
                    setShowNotice(false)
                }}
            >
                <DialogContent className={'!max-w-[760px]'}>
                    <DialogHeader>
                        <DialogTitle>Cookie instellingen</DialogTitle>
                    </DialogHeader>
                    <div className="grid grid-cols-1 gap-y-4">
                        <div className="border-brand-grey400 flex flex-col border">
                            <Label className="bg-brand-grey400 border-brand-grey400 flex gap-4 border-b p-4">
                                <Checkbox disabled className="first:[&>*>svg]:fill-black" id="necessary" checked />
                                <Label className="text-white">Noodzakelijk</Label>
                            </Label>
                            <p className="p-4">
                                Noodzakelijke cookies helpen een website bruikbaarder te maken, door basisfuncties als
                                paginanavigatie en toegang tot beveiligde gedeelten van de website mogelijk te maken.
                                Zonder deze cookies kan de website niet naar behoren werken.
                            </p>
                        </div>

                        <div
                            className={classNames(
                                `flex flex-col border transition-colors`,
                                { 'border-brand-red': statisticChecked },
                                { ' border-black': !statisticChecked },
                            )}
                        >
                            <Label
                                aria-label="Wijzig statistieken cookies"
                                htmlFor="statistics"
                                className={classNames(
                                    `flex gap-4 border-b p-4 transition-colors hover:cursor-pointer`,
                                    { 'bg-brand-red border-brand-red': statisticChecked },
                                    { 'border-black bg-white': !statisticChecked },
                                )}
                            >
                                <Checkbox
                                    defaultChecked={statisticChecked}
                                    onCheckedChange={value => {
                                        updateCookiePreferences({ statistics: value as boolean })
                                    }}
                                    id="statistics"
                                />

                                <Label
                                    htmlFor="statistics"
                                    className={classNames(`transition-colors hover:cursor-pointer`, {
                                        'text-white': statisticChecked,
                                    })}
                                >
                                    Statistieken
                                </Label>
                            </Label>
                            <p className="p-4">
                                Statistische cookies helpen eigenaren van websites begrijpen hoe bezoekers hun website
                                gebruiken, door anoniem gegevens te verzamelen en te rapporteren.
                            </p>
                        </div>

                        <div
                            className={classNames(
                                `flex flex-col border transition-colors`,
                                { 'border-brand-red': marketingChecked },
                                { ' border-black': !marketingChecked },
                            )}
                        >
                            <Label
                                aria-label="Wijzig marketing cookies"
                                htmlFor="marketing"
                                className={classNames(
                                    `flex gap-4 border-b p-4 transition-colors hover:cursor-pointer`,
                                    { 'bg-brand-red border-brand-red': marketingChecked },
                                    { 'border-black bg-white': !marketingChecked },
                                )}
                            >
                                <Checkbox
                                    defaultChecked={marketingChecked}
                                    onCheckedChange={value => updateCookiePreferences({ marketing: value as boolean })}
                                    id="marketing"
                                />

                                <Label
                                    htmlFor="marketing"
                                    className={classNames(`transition-colors hover:cursor-pointer`, {
                                        'text-white': marketingChecked,
                                    })}
                                >
                                    Marketing
                                </Label>
                            </Label>
                            <p className="p-4">
                                Marketingcookies worden gebruikt om bezoekers te volgen wanneer ze verschillende
                                websites bezoeken. Hun doel is advertenties weergeven die zijn toegesneden op en
                                relevant zijn voor de individuele gebruiker. Deze advertenties worden zo waardevoller
                                voor uitgevers en externe adverteerders.
                            </p>
                        </div>
                        <Button
                            onClick={() => {
                                setDialogOpen(false)
                                setShowNotice(false)
                            }}
                            aria-label="Voorkeuren opslaan"
                            as="button"
                            type="submit"
                            variant="primary"
                        >
                            Voorkeuren opslaan
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CookieNotification
