const routes = {
    modelDetail: '/:model-slug',
    home: '/',
    category: '/:model-slug/:category-slug',
    allCategories: '/:model-slug/producten',
    productDetail: '/product/:handle',
    product: '/product',
    model: '/model',
    search: '/zoeken/:zoekterm',
    shoppingCart: '/winkelmand',
    checkout: '/bestellen',
    checkPayment: '/bestellen/betaling-controleren',
    paymentFailed: '/bestellen/betaling-mislukt',
    paymentSuccess: '/bestellen/bedankt/:cart_id',
    conditions: 'voorwaarden',
    faq: '/faq',
    revocation: '/herroeping',
    contact: '/contact',
    pageNotFound: '/404',
    privacy: '/privacy',
    disclaimer: '/disclaimer',
    thankYou: '/bedankt',
}

export default routes
