import React, { FC, SVGAttributes } from 'react'

const SearchIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M10.879 3.758a7.115 7.115 0 015.718 11.363l3.846 3.253a1.471 1.471 0 11-2.073 2.073l-3.254-3.846A7.123 7.123 0 1110.88 3.758zm0 12.75a5.625 5.625 0 100-11.25 5.625 5.625 0 000 11.25z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export default SearchIcon
