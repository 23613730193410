'use client'

import * as React from 'react'
import { forwardRef } from 'react'
import { InputFieldProps } from './types'
import classNames from 'classnames'
import { get, useFormContext, useFormState } from 'react-hook-form'

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
    ({ className, placeholder, type = 'text', name, ...props }, ref) => {
        const {
            formState: { errors },
        } = useFormContext()
        const error = get(errors, name)

        return (
            <>
                <input
                    id={name}
                    name={name}
                    ref={ref}
                    className={classNames(
                        `border-brand-grey800 text-dark h-10 w-full border-[1px] px-3 py-4`,
                        {
                            'border-brand-red focus:border-red-500 focus:ring-red-500': error,
                        },
                        className
                    )}
                    type={type}
                    placeholder={placeholder}
                    {...props}
                />
                {error && <p className="error text-brand-red pt-1">{error?.message}</p>}
            </>
        )
    }
)

InputField.displayName = 'InputField'

export default InputField
