'use client'
import React, { ReactNode } from 'react'
import { PUBLIC_MEDUSA_BACKEND_URL, medusaClient } from '@lib/config'
import { CartProvider, MedusaProvider } from 'medusa-react'
import { QueryClient } from '@tanstack/react-query'
import { DealerProps, StoreProvider } from '@lib/context/store-context'
import { CartSidePanelProvider } from '@lib/context/cart-show-context'
import { ProductCategory } from '@medusajs/medusa'

export function Providers({
    dealers,
    allCarModels,
    children,
}: {
    dealers: DealerProps
    allCarModels: ProductCategory[]
    children: ReactNode
}) {
    return (
        <MedusaProvider
            // @ts-ignore
            medusaClient={medusaClient}
            baseUrl={PUBLIC_MEDUSA_BACKEND_URL}
            queryClientProviderProps={{
                client: new QueryClient({
                    defaultOptions: {
                        queries: {
                            refetchOnWindowFocus: false,
                            staleTime: 1000 * 60 * 60 * 24,
                            retry: 1,
                        },
                    },
                }),
            }}
        >
            <CartSidePanelProvider>
                <CartProvider>
                    <StoreProvider dealers={dealers} allCarModels={allCarModels}>
                        {children}
                    </StoreProvider>
                </CartProvider>
            </CartSidePanelProvider>
        </MedusaProvider>
    )
}
