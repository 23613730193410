import React, { FC, SVGAttributes } from 'react'

const PlusIcon: FC<SVGAttributes<SVGElement>> = ({ className, ...props }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="none"
        viewBox="0 0 25 25"
    >
        <path
            fill="#000"
            fillRule="evenodd"
            d="M12.006 24.01a12 12 0 110-24 12 12 0 010 24zm0-22a10 10 0 100 20.001 10 10 0 000-20zm1 16.5a1 1 0 01-2 0v-5.5h-5.5a1 1 0 010-2h5.5v-5.5a1 1 0 112 0v5.5h5.5a1 1 0 110 2h-5.5v5.5z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export default PlusIcon
