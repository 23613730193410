'use client'
import Script from 'next/script'
import { useCookie } from 'web/src/lib/context/cookie-context'
import React from 'react'

type ScriptList = {
    id: string
    script?: string
    src?: string
    loadingStrategy?: 'afterInteractive' | 'worker' | 'beforeInteractive' | 'lazyOnload'
}

const WebsiteScripts = () => {
    const { cookiePreferences } = useCookie()
    const scripts: ScriptList[] = []

    if (cookiePreferences?.statistics) {
        // Add Google Analytics
        if (process.env.NEXT_PUBLIC_GTAG_API_KEY) {
            scripts.push({
                loadingStrategy: 'lazyOnload',
                id: 'google-analytics-script',
                src: `https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTAG_API_KEY}`,
            })
        }
    }

    return (
        <>
            {scripts?.map(({ loadingStrategy, id, script, src }) => {
                if (src) {
                    return (
                        <Script
                            strategy={loadingStrategy || 'afterInteractive'}
                            type="text/javascript"
                            key={id}
                            id={id}
                            src={src}
                        />
                    )
                } else if (script) {
                    return (
                        <Script
                            strategy={loadingStrategy || 'afterInteractive'}
                            type="text/javascript"
                            key={id}
                            id={id}
                            dangerouslySetInnerHTML={{ __html: script }}
                        />
                    )
                } else {
                    return null
                }
            })}
        </>
    )
}

export default WebsiteScripts
