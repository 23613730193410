'use client'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import CartIcon from 'ui/icons/CartIcon'
import SearchIcon from 'ui/icons/SearchIcon'
import Button from 'ui/components/Button'
import classNames from 'classnames'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Logo from 'ui/icons/Logo'
import { usePathname, useRouter } from 'next/navigation'
import { CartSidePanel } from 'web/src/lib/context/cart-show-context'
import NextBreadcrumbs from 'ui/components/Breadcrumbs'
import { useCart } from 'medusa-react'
import LoadingIcon from 'ui/icons/LoadingIcon'
import InputField from '../FormFields/InputField'
import routes from 'web/src/lib/routes'
import { useStore } from 'web/src/lib/context/store-context'
import HamburgerIcon from '../../icons/HamburgerIcon'
import { scrollLock } from '../../utils/scrollLock'
import { handleEscKeyPress } from '../../utils/handleEscKeyPress'

const schema = Yup.object({
    searchQuery: Yup.string(),
    searchQueryMobile: Yup.string(),
})
const Header = () => {
    const [path, setPath] = useState<string>('')
    const [showMobileSearch, setShowMobileSearch] = useState<boolean>(false)
    const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)
    const { isSearchingProducts, setIsSearchingProducts } = useStore()
    const searchRef = useRef<HTMLDivElement>(null)
    const menuRef = useRef<HTMLDivElement>(null)

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: { searchQuery: '', searchQueryMobile: '' },
    })
    const { open } = CartSidePanel()
    const { cart } = useCart()
    const pathName = usePathname()
    const searchQuery = methods?.watch('searchQuery')
    const searchQueryMobile = methods?.watch('searchQueryMobile')
    const router = useRouter()
    const checkoutPaths = ['/bestellen']

    useEffect(() => {
        if (pathName) {
            setPath(pathName)
        } else {
            setPath('')
        }
    }, [pathName])
    const handleSearch = (searchQuery: string) => {
        setIsSearchingProducts(true)
        router.push(routes.search.replace(':zoekterm', searchQuery))
        methods.reset()
    }

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
        if (e.keyCode === 13 && searchQuery) {
            handleSearch(searchQuery)
        }
        if (e.keyCode === 13 && searchQueryMobile) {
            handleSearch(searchQueryMobile)
        }
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
                setShowMobileSearch(false), scrollLock.disable()
            }

            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setShowMobileMenu(false), scrollLock.disable()
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [searchRef, menuRef])

    useEffect(() => {
        if (showMobileSearch) {
            scrollLock.enable()
            document.addEventListener('keydown', e => handleEscKeyPress(e, () => setShowMobileSearch(false)))
        } else {
            scrollLock.disable()
            document.removeEventListener('keydown', e => handleEscKeyPress(e, () => setShowMobileSearch(false)))
        }
        return () =>
            document.removeEventListener('keydown', e => handleEscKeyPress(e, () => setShowMobileSearch(false)))
    }, [showMobileSearch])

    useEffect(() => {
        if (showMobileMenu) {
            scrollLock.enable()
            document.addEventListener('keydown', e => handleEscKeyPress(e, () => setShowMobileMenu(false)))
        } else {
            scrollLock.disable()
            document.removeEventListener('keydown', e => handleEscKeyPress(e, () => setShowMobileMenu(false)))
        }
        return () => document.removeEventListener('keydown', e => handleEscKeyPress(e, () => setShowMobileMenu(false)))
    }, [showMobileMenu])

    return (
        <FormProvider {...methods}>
            <header
                className={classNames(
                    `relative z-10 flex justify-center bg-black text-white`,
                    { 'h-full': showMobileSearch },
                    { 'h-20': !showMobileSearch },
                )}
            >
                <div className="container flex flex-col">
                    <div className="flex h-20 items-center justify-between">
                        <div className={'flex gap-x-8'}>
                            <Link aria-label="Ga terug naar de homepagina" href="/">
                                <Logo />
                            </Link>
                            {!checkoutPaths.includes(path) && (
                                <div className={'hidden items-center lg:flex'}>
                                    <NextBreadcrumbs />
                                </div>
                            )}
                        </div>
                        <div className={'flex items-center gap-4'}>
                            <ul className="md:flex items-center gap-4 pr-6 hidden">
                                <li>
                                    <Link
                                        className="hover:text-brand-red font-medium uppercase"
                                        href={'/category/sale'}
                                    >
                                        Sale
                                    </Link>
                                </li>
                            </ul>
                            {!checkoutPaths.includes(path) && (
                                <>
                                    <div className="relative hidden sm:block">
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-2.5">
                                            <Button
                                                aria-label={'zoeken'}
                                                iconLeft={isSearchingProducts ? <LoadingIcon /> : <SearchIcon />}
                                                as={'button'}
                                                onClick={() => {
                                                    setShowMobileSearch(prevState => !prevState)
                                                    searchQuery && handleSearch(searchQuery)
                                                }}
                                            />
                                        </span>
                                        <InputField
                                            onKeyDown={handleKeyDown}
                                            className="focus:border-brand-red h-10 !w-[184px] border border-white bg-black px-3 py-4 pl-12 text-white placeholder:text-white focus:border"
                                            placeholder="Zoeken..."
                                            id="searchQuery"
                                            type="search"
                                            {...methods.register('searchQuery')}
                                        />
                                    </div>
                                    <Button
                                        as="button"
                                        aria-label={'zoeken'}
                                        type="button"
                                        onClick={() => setShowMobileSearch(prevState => !prevState)}
                                        className="flex h-10 !w-14 flex-shrink-0 items-center justify-center border border-white sm:hidden"
                                    >
                                        <SearchIcon />
                                    </Button>
                                </>
                            )}
                            <Button
                                as="button"
                                type="button"
                                onClick={open}
                                className={
                                    'btn-plain flex h-10 items-center gap-3 border border-white bg-black px-4 text-white [&>svg]:shrink-0'
                                }
                            >
                                <CartIcon />
                                <p className="flex">
                                    {cart ? (
                                        cart.items.reduce((accumulator, object) => {
                                            return accumulator + object.quantity
                                        }, 0)
                                    ) : (
                                        <LoadingIcon />
                                    )}
                                </p>
                            </Button>
                            <Button
                                as="button"
                                type="button"
                                onClick={() => setShowMobileMenu(prevState => !prevState)}
                                className={
                                    'md:hidden btn-plain flex h-10 items-center gap-3 border border-white bg-black px-4 text-white [&>svg]:shrink-0'
                                }
                            >
                                <HamburgerIcon />
                            </Button>
                        </div>
                    </div>
                </div>
            </header>
            <div
                ref={searchRef}
                className={classNames(
                    `absolute flex h-20 w-full items-center gap-4 bg-black p-4 z-[5] transition-all duration-300 ease-in-out sm:hidden`,
                    { 'top-0 -translate-y-full': !showMobileSearch },
                    { 'top-20 translate-y-0': showMobileSearch },
                )}
            >
                {!checkoutPaths.includes(path) && (
                    <div className="relative flex w-full">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-2.5">
                            <Button
                                aria-label={'zoeken'}
                                iconLeft={isSearchingProducts ? <LoadingIcon /> : <SearchIcon />}
                                as={'button'}
                                onClick={() => {
                                    searchQueryMobile && handleSearch(searchQueryMobile)
                                    setShowMobileSearch(prevState => !prevState)
                                }}
                            />
                        </span>
                        <InputField
                            onKeyDown={handleKeyDown}
                            className="focus:border-brand-red h-10 w-full border border-white bg-black px-3 py-4 pl-12 text-white placeholder:text-white focus:border"
                            placeholder="Zoeken..."
                            type="searchQueryMobile"
                            {...methods.register('searchQueryMobile')}
                        />
                    </div>
                )}
                <Button
                    className="bg-brand-grey800 h-10 w-fit px-4 py-2 font-bold uppercase text-white"
                    as={'button'}
                    type={'button'}
                    onClick={() => setShowMobileSearch(prevState => !prevState)}
                >
                    Terug
                </Button>
            </div>

            <div
                className={classNames(
                    `absolute flex h-auto w-full items-center gap-4 bg-black p-4 z-[5] py-6 transition-all duration-300 ease-in-out md:hidden`,
                    { 'top-0 -translate-y-full': !showMobileMenu },
                    { 'top-20 translate-y-0': showMobileMenu },
                )}
                ref={menuRef}
            >
                <ul className="flex flex-col gap-4 container">
                    <li>
                        <Link
                            className="text-white font-medium uppercase"
                            href={'/category/sale'}
                            onClick={() => setShowMobileMenu(false)}
                        >
                            Sale
                        </Link>
                    </li>
                </ul>
            </div>
            <div
                className={classNames(
                    'bg-black/40 backdrop-blur-[2px] md:hidden absolute h-full z-[4] transition-all duration-300 ease-in-out w-full',
                    { 'opacity-0 invisible': !showMobileMenu && !showMobileSearch },
                    { 'opacity-100 visible': showMobileMenu || showMobileSearch },
                )}
            ></div>
        </FormProvider>
    )
}

export default Header
