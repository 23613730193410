export const checkBreadcrumbsUrl = (pathName: string | null) => {
    if (!pathName) return

    const checkoutPaths = ['/bestellen']
    if (pathName) {
        if (checkoutPaths.includes(pathName)) {
            return true
        }
    }
}
