'use client'
import useToggleState from '@lib/hooks/use-toggle-state'
import { createContext, useContext } from 'react'

interface CartSidePanelContext {
    state: boolean
    open: () => void
    close: () => void
}

export const CartSidePanelContext = createContext<CartSidePanelContext | null>(null)

export const CartSidePanelProvider = ({ children }: { children: React.ReactNode }) => {
    const { state, close, open } = useToggleState()

    return <CartSidePanelContext.Provider value={{ state, close, open }}>{children}</CartSidePanelContext.Provider>
}

export const CartSidePanel = () => {
    const context = useContext(CartSidePanelContext)

    if (context === null) {
        throw new Error('useCartSidePanel must be used within a CartSidePanelProvider')
    }

    return context
}
