import React, { FC, SVGAttributes } from 'react'

const CloseIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
        <path
            fill="#000"
            fillRule="evenodd"
            d="M17.78 17.788a.752.752 0 01-1.062 0l-4.725-4.725-4.72 4.721a.75.75 0 01-1.062-1.061l4.721-4.721-4.72-4.72A.75.75 0 017.273 6.22l4.72 4.72 4.725-4.724a.75.75 0 111.06 1.062l-4.723 4.724 4.725 4.725a.75.75 0 010 1.061z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export default CloseIcon
