import React, { FC, SVGAttributes } from 'react'

const MinusIcon: FC<SVGAttributes<SVGElement>> = ({ className, ...props }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="none"
        viewBox="0 0 25 25"
    >
        <path
            fill="#000"
            fillRule="evenodd"
            d="M12.006 24.01a12 12 0 110-24 12 12 0 010 24zm0-22a10 10 0 100 20.001 10 10 0 000-20zm-7.5 10a1 1 0 011-1h13a1 1 0 010 2h-13a1 1 0 01-1-1z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export default MinusIcon
