import React, { FC, SVGAttributes } from 'react'

const CartIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M7.84 14.915a.797.797 0 00-.7.79.798.798 0 00.799.797h10.096a.75.75 0 110 1.5H7.71a2.253 2.253 0 01-2.23-1.931L4.022 5.896a.75.75 0 00-.743-.644H1.506a.75.75 0 010-1.5H3.28a2.253 2.253 0 012.23 1.932l.046.318h13.588a.75.75 0 01.744.857l-.854 5.963a.938.938 0 01-.813.798L7.84 14.915zm-.323 3.837a1.5 1.5 0 11-.005 3 1.5 1.5 0 01.005-3zm9.015 0a1.5 1.5 0 11-.005 3.001 1.5 1.5 0 01.005-3z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export default CartIcon
