'use client'

import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import CheckIcon from '../../../icons/CheckIcon'
import classNames from 'classnames'

const Checkbox = React.forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => {
    return (
        <CheckboxPrimitive.Root
            ref={ref}
            className={classNames(
                'peer flex h-6 w-6 shrink-0 items-center justify-center border border-black bg-white focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2',
                className
            )}
            {...props}
        >
            <CheckboxPrimitive.Indicator className={classNames('flex items-center justify-center')}>
                <CheckIcon />
            </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
    )
})
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
