import { gtagEnabled } from './index'
import { formatMedusaPrice } from '../formatMedusaPrice'
import { LineItem } from '@medusajs/medusa'

export const trackAddToCartLineItem = (item: LineItem, quantity: number, price: number) => {
    if (gtagEnabled()) {
        gtag('event', 'add_to_cart', {
            currency: 'EUR',
            value: formatMedusaPrice(item.unit_price),
            items: [
                {
                    item_name: item.title,
                    item_id: item.variant_id,
                    quantity: quantity,
                    price: price,
                },
            ],
        })
    }
}
